







































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import { Action, Getter } from 'vuex-class'
import {
  openAiQueryKeys,
  useGetAIScript,
  useGetAITemplates,
  useGetOpenAiContext,
  useSaveScripts,
  useUpdateScript,
} from '@/hooks/openAi'
import { computed, reactive, ref, SetupContext, watch } from '@vue/composition-api'
import { QueryClient, useQueryClient } from 'vue-query'
import get from 'lodash.get'
import { formatOpenAiCaption, getQueryParam } from '@/utils/misc'
import AdoriService from '@/services/adori'
import AdoriServiceV6 from '@/services/adori_v6'
import { uploadStatus } from '@/components/Publish/publish'
import * as Sentry from '@sentry/vue'
import { audioQueryKeys } from '@/hooks/audio'
import ModalLoader from '@/components/Modals/ModalLoader.vue'
import ModalDemoLoader from '@/components/Modals/ModalDemoLoader.vue'
import AIPanel from '@/components/AI/Panes/AIPanel.vue'

import { voiceToneList, writingStyleList, topicList, languageList } from '@/components/AI/setting'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    ModalLoader,
    ModalDemoLoader,
    AIPanel,
  },
  setup(props: any, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const openAiQuery = ref('')
    const openAiRes = ref('')
    const openAiDesc = ref('')
    const openAiTemplate = ref('')
    const openAiKeywords = ref('')
    const openAiVoice = ref('')
    const openAiStyle = ref('')
    const openAiLang = ref('')
    const openAiParams = reactive({
      topic: openAiQuery,
      max_tokens: 4000,
      temperature: 0.7,
      description: openAiDesc,
      template: openAiTemplate,
      keywords: openAiKeywords,
      voiceTone: openAiVoice,
      writingStyle: openAiStyle,
      language: openAiLang,
    })
    const isEnabled = computed(() => !!openAiQuery.value)
    const { data: openAiData, isFetching: isOpenAiFetching } = useGetOpenAiContext(openAiParams, {
      enabled: isEnabled,
      onSuccess() {
        isOpenAiFetching.value = false
      },
      onError() {
        isOpenAiFetching.value = false
      },
    })
    const finalDraft = ref('')

    watch(openAiData, (curVal) => {
      openAiRes.value = curVal
      if (templateEnum.value != 'GENERAL_CHATBOT') {
        finalDraft.value = curVal
      }
    })

    const scriptId = computed(() => root.$route.params.id)
    const isScriptEnabled = computed(() => root.$route.params.id !== 'new')
    const templateEnum = computed(() => root.$route.query.enum)
    const templateName = computed(() => root.$route.query.name)
    const { data: scriptData, isLoading: isScriptLoading } = useGetAIScript(
      scriptId,
      { enabled: isScriptEnabled },
      (data: any) => {
        finalDraft.value = data.ideaContent
      }
    )
    if (get(scriptData.value, 'ideaContent', false)) {
      finalDraft.value = get(scriptData.value, 'ideaContent', '')
    }
    watch(scriptData, (curVal) => {
      finalDraft.value = get(curVal, 'ideaContent', '')
    })

    const isUnsavedChanges = ref(false)
    const { mutate: saveScript, isLoading: isSaveScriptLoading } = useSaveScripts((data: any) => {
      isUnsavedChanges.value = false
      queryClient.invalidateQueries(openAiQueryKeys.AI_SCRIPTS)
      root.$router.replace(`/ai/template/${data.id}?enum=${templateEnum.value}`)
    })
    const { mutate: updateScript, isLoading: isUpdateScriptLoading } = useUpdateScript((data: any) => {
      isUnsavedChanges.value = false
      queryClient.invalidateQueries(openAiQueryKeys.AI_SCRIPTS)
      queryClient.invalidateQueries([openAiQueryKeys.AI_SCRIPT, scriptId])
    })
    const { data: aiTemplateList } = useGetAITemplates()

    return {
      queryClient,
      openAiData,
      isOpenAiFetching,
      openAiQuery,
      openAiRes,
      isUnsavedChanges,
      saveScript,
      isSaveScriptLoading,
      finalDraft,
      isScriptLoading,
      updateScript,
      isUpdateScriptLoading,
      templateEnum,
      templateName,
      openAiDesc,
      openAiTemplate,
      openAiKeywords,
      openAiVoice,
      openAiStyle,
      openAiLang,
      scriptData,
      aiTemplateList,
    }
  },
})
export default class ViewAiTemplateEditor extends Vue {
  @Getter openAiPayload!: any
  @Getter networkId!: any
  @Getter audio!: any

  @Action closeModal!: any
  @Action aiToAudio!: any
  @Action getAudioUids!: any
  @Action addAudioUid!: any
  @Action setShowPane!: any

  isModalLoader = false
  isModalDemoLoader = false
  voiceTone: string = 'Default'
  writingStyle: string = 'Default'

  topic = ''
  description = ''
  keywords = ''
  scriptName = ''
  language = 'English'
  showAIPanel: boolean = false

  queryClient!: QueryClient
  finalDraft!: any
  openAiRes!: any
  openAiQuery!: any
  openAiDesc!: any
  openAiTemplate!: any
  openAiKeywords!: any
  openAiVoice!: any
  openAiStyle!: any
  openAiLang!: any
  openAiData!: any
  isOpenAiFetching!: any
  isScriptLoading!: boolean
  isUnsavedChanges!: boolean
  saveScript!: any
  isSaveScriptLoading!: boolean
  updateScript!: any
  isUpdateScriptLoading!: boolean
  scriptId!: any
  templateEnum!: any
  templateName!: any
  scriptData!: any
  isConverting = false
  btnCreateText = 'Create Video'

  get btnGenerateText() {
    return this.isOpenAiFetching ? 'Generating' : 'Generate'
  }

  get tagEditStoreData() {
    return this.$store.state.modal.tagEdit
  }

  get topicListData() {
    return topicList
  }

  get voiceToneListData() {
    return voiceToneList
  }
  get writingStyleData() {
    return writingStyleList
  }
  get languageData() {
    return languageList
  }

  get title() {
    return get(this, 'aiTemplateList', []).length ? this.templateList[this.templateEnum].name : ''
  }

  get templateList() {
    return get(this, 'aiTemplateList', []).reduce((acc: any, obj: any) => {
      acc[obj.enum] = obj
      return acc
    }, {})
  }

  toggleAIPanel() {
    this.setShowPane(true)
  }

  selectItem(item: any) {
    this.topic = item
    this.setShowPane(false)
  }
  moveToFinal() {
    this.finalDraft += `${this.openAiRes}\n\n`
    this.openAiRes = ''
    this.isUnsavedChanges = true
  }

  sendQuery() {
    this.isOpenAiFetching = true
    this.openAiQuery = this.topic
    this.openAiDesc = this.description
    this.openAiTemplate = this.templateEnum
    this.openAiKeywords = this.keywords
    this.openAiVoice = this.voiceTone
    this.openAiStyle = this.writingStyle
    this.openAiLang = this.language
  }

  discardFinalScript() {
    this.$store.dispatch('showConfirm', {
      title: 'Discard Draft',
      description: 'Are you sure you want to discard the content?',
      yesButtonText: 'Yes',
      onConfirm: async () => {
        this.finalDraft = ''
        this.closeModal()
      },
    })
  }

  saveDraft() {
    const data: any = {
      name: this.scriptName ? this.scriptName : this.topic,
      ideaContent: this.finalDraft,
      topic: this.topic,
      template: this.templateEnum,
      description: this.description,
      keywords: this.keywords,
      voiceTone: this.voiceTone,
      writingStyle: this.writingStyle,
      language: this.language,
    }
    if (this.$route.params.id === 'new') {
      this.saveScript(data)
    } else {
      data['id'] = this.$route.params.id
      this.updateScript(data)
    }
  }

  @Watch('finalDraft')
  handleDraft() {
    this.isUnsavedChanges = true
  }
  created() {
    if (this.scriptData) {
      this.topic = this.scriptData.topic
      this.description = this.scriptData.description
      this.keywords = this.scriptData.keywords
      this.language = this.scriptData.language
      this.scriptName = this.scriptData.name
      this.voiceTone = this.scriptData.voiceTone
      this.writingStyle = this.scriptData.writingStyle
    }
  }

  @Watch('scriptData')
  handleEdit() {
    if (this.$route.params.id !== 'new') {
      this.topic = this.scriptData.topic
      this.description = this.scriptData.description
      this.keywords = this.scriptData.keywords
      this.language = this.scriptData.language
      this.scriptName = this.scriptData.name
      this.voiceTone = this.scriptData.voiceTone
      this.writingStyle = this.scriptData.writingStyle
    }
  }

  handleVoiceDetails(voiceDetails: any) {
    this.uploadScript(voiceDetails)
  }

  async uploadScript(voiceDetails: any) {
    try {
      this.saveDraft()
      this.isConverting = true
      this.btnCreateText = 'Converting to Audio ....'

      const encoder = new TextEncoder()
      const encodedText = encoder.encode(this.finalDraft)
      const blob = new Blob([encodedText], { type: 'text/plain;charset=utf-8' })
      const file = new File([blob], `${crypto.randomUUID()}.txt`, { type: 'text/plain;charset=utf-8' })

      const suggestions = this.aiToAudio(this.finalDraft)
      let image: any = AdoriService.uploadImage(this.networkId, {
        url: 'https://i.ibb.co/1Q0Y3j7/pod-placeholder.jpg',
      })
      const description = await suggestions
      image = await image
      let selectedEpisode: any = {
        name: this.topic,
        description,
        imageId: image.id,
        keywords: [],
        explicitContent: false,
        isRss: false,
        audioUrl: '',
        imageUrl: image.originalUrl,
        uploadId: null,
        trackSource: 'AI_GENERATED',
      }

      selectedEpisode = { ...selectedEpisode, ...voiceDetails }

      const payload = {
        file,
        track_info: selectedEpisode,
      }

      const taskId = await this.$store.dispatch('createAudioTrackFromFile', payload)
      const convertToAudio = async () => {
        const res: any = await AdoriService.getTTSUploadStatus(this.networkId, taskId)
        if (res.status === uploadStatus.FINISHED) {
          await this.getAudioUids({
            clear: true,
          })
          const audioTrack = this.audio(res.audioUid)

          this.$store.dispatch('clearAudioUploader')
          this.addAudioUid(res.audioUid)
          this.generateImages(audioTrack)
          return
        } else if (res.status === uploadStatus.FAILED) {
          this.$store.dispatch('pushNotification', {
            text: res.errorMessage || 'Convert Failed',
            type: 'ERROR',
          })
          this.isConverting = false
          this.btnCreateText = 'Create Audio'
          return
        } else {
          setTimeout(convertToAudio, 3000)
        }
      }
      await convertToAudio()
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Convert Failed',
        type: 'ERROR',
      })
      this.isConverting = false
      this.btnCreateText = 'Create Audio'
    }
  }

  async generateImages(audioTrack: any) {
    this.btnCreateText = 'Generating AI images ....'
    try {
      const transcript: any = await AdoriService.fetchAudioTranscript(this.networkId, audioTrack.uid)
      const sentences = transcript.sentences
      const openAiPayload = this.$store.getters.openAiPayload['image']
      const finalArray: any = []
      sentences.map(async (sentence: any, index: number) => {
        const openAi: any = await AdoriServiceV6.openAi(this.networkId, {
          //   ...openAiPayload,
          topic: `write an image prompt for this description:${sentence.text}. Give only prompt as response.`,
          context: 'image',
        })
        const dalleRes: any = await AdoriServiceV6.dalleResults(this.networkId, {
          prompt: formatOpenAiCaption(openAi.result),
          size: '1024x1024',
          n: 1,
        })

        if (dalleRes.length) {
          finalArray.push({
            time: sentence.start_time,
            url: dalleRes[0].url,
          })
        } else {
          const unsplashRes: any = await AdoriService.searchImages(this.networkId, 'unsplash', {
            limit: 10,
            offset: 0,
            query: formatOpenAiCaption(openAi.result),
          })
          if (unsplashRes.data.length) {
            finalArray.push({
              time: sentence.start_time,
              url: unsplashRes.data[0].urls.full,
            })
          } else {
            const googleRes: any = await AdoriService.searchImages(this.networkId, 'google', {
              limit: 10,
              offset: 0,
              query: formatOpenAiCaption(openAi.result),
            })
            if (googleRes.data.length) {
              finalArray.push({
                time: sentence.start_time,
                url: googleRes.data[0].urls.full,
              })
            } else {
              finalArray.push(false)
            }
          }
        }
        finalArray.length === sentences.length && this.createTags(finalArray, audioTrack)
      })
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'AI image generation failed!! try again later',
        type: 'WARNING',
      })

      this.goToOnboarding(audioTrack)
      this.isConverting = false
      this.btnCreateText = 'Create Audio'
    }
  }

  async createTags(imagesArray: any, audioTrack: any) {
    this.btnCreateText = 'Creating visuals ...'
    if (imagesArray.length == 0) {
      this.goToOnboarding(audioTrack)
    }
    if (imagesArray.length == 0) {
      this.goToOnboarding(audioTrack)
    }
    try {
      const tempArray = []
      await this.$store.dispatch('getAudioTags', audioTrack.uid)
      imagesArray.map(async (imageObj: any, index: number) => {
        if (imageObj) {
          const image: any = await AdoriService.uploadImage(this.networkId, {
            url: imageObj.url,
          })
          this.tagEditStoreData.data.tagType = 'photo'
          this.tagEditStoreData.data.canvasData = {
            version: '4.6.0',
            objects: [
              {
                type: 'rect',
                version: '4.6.0',
                originX: 'left',
                originY: 'top',
                left: 0,
                top: 0,
                width: 1080,
                height: 1080,
                fill: {
                  type: 'linear',
                  coords: {
                    x1: 0,
                    y1: 540,
                    x2: 1920,
                    y2: 0,
                  },
                  colorStops: [
                    {
                      offset: 0,
                      color: '#21D4FD',
                    },
                    {
                      offset: 1,
                      color: '#B721FF',
                    },
                  ],
                  offsetX: 0,
                  offsetY: 0,
                  gradientUnits: 'pixels',
                  gradientTransform: null,
                },
                stroke: null,
                strokeWidth: 1,
                strokeDashArray: null,
                strokeLineCap: 'butt',
                strokeDashOffset: 0,
                strokeLineJoin: 'miter',
                strokeUniform: false,
                strokeMiterLimit: 4,
                scaleX: 1,
                scaleY: 1,
                angle: 0,
                flipX: false,
                flipY: false,
                opacity: 1,
                shadow: null,
                visible: true,
                backgroundColor: '',
                fillRule: 'nonzero',
                paintFirst: 'fill',
                globalCompositeOperation: 'source-over',
                skewX: 0,
                skewY: 0,
                rx: 0,
                ry: 0,
                id: 'artboard',
              },
              {
                type: 'image',
                version: '4.6.0',
                originX: 'left',
                originY: 'top',
                left: 0,
                top: 0,
                width: 1040,
                height: 1040,
                fill: 'rgb(0,0,0)',
                stroke: null,
                strokeWidth: 0,
                strokeDashArray: null,
                strokeLineCap: 'butt',
                strokeDashOffset: 0,
                strokeLineJoin: 'miter',
                strokeUniform: false,
                strokeMiterLimit: 4,
                scaleX: 1.05,
                scaleY: 1.05,
                angle: 0,
                flipX: false,
                flipY: false,
                opacity: 1,
                shadow: null,
                visible: true,
                backgroundColor: '',
                fillRule: 'nonzero',
                paintFirst: 'fill',
                globalCompositeOperation: 'source-over',
                skewX: 0,
                skewY: 0,
                cropX: 0,
                cropY: 0,
                src: image.originalUrl,
                crossOrigin: 'anonymous',
                filters: [],
              },
            ],
          }
          this.tagEditStoreData.tagTitle = 'AI TAG'
          this.tagEditStoreData.data.orientation = 'SQUARE'
          this.tagEditStoreData.data.imageId = image.id
          const timelinePosition = parseInt(imageObj.time.replace('s', '')) * 1000

          const tagId = await this.$store.dispatch('uploadTag', false)
          const tagItem = {
            tagId,
            offsetMillis: timelinePosition,
          }
          await this.$store.dispatch('addAudioTag', {
            audioUid: audioTrack.uid,
            tagPosition: tagItem,
          })
          tempArray.push(tagId)
        } else {
          tempArray.push(false)
        }

        if (tempArray.length === imagesArray.length) {
          await this.$store.dispatch('uploadAudioTags', audioTrack.uid)
          this.goToOnboarding(audioTrack)
        }
      })
    } catch (error) {
      Sentry.captureException(error)
      this.$store.dispatch('pushNotification', {
        text: 'Generating some tags Failed!!! try again later',
        type: 'WARNING',
      })
      await this.$store.dispatch('uploadAudioTags', audioTrack.uid)
      this.goToOnboarding(audioTrack)
      this.isConverting = false
      this.btnCreateText = 'Create Audio'
    }
  }

  goToOnboarding(audioTrack: any) {
    const selectedEpisode = {
      audioUrl: audioTrack.audioUrl,
      name: audioTrack.name,
      description: audioTrack.description,
      explicitContent: audioTrack.explicitContent,
      guid: audioTrack.guid,
      imageId: audioTrack.imageId,
      imageUrl: audioTrack.imageUrl,
      isRss: false,
      keywords: audioTrack.keywords,
      durationSeconds: audioTrack.durationMillis / 1000,
      isReUpload: true,
    }
    this.$store.commit('unselectAllEpisodeForUpload')
    this.$store.commit('clearYoutubeState')
    this.$store.commit('resetYoutubeSetting')
    this.$store.commit('selectEpisodeForUpload', selectedEpisode)
    this.$store.commit('setYoutubeStep', 2)
    sessionStorage.setItem('upload', 'YES')
    sessionStorage.setItem('onboardingTrack', JSON.stringify(selectedEpisode))
    this.$router.push('/onboarding')
    this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
  }
}
