var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c("div", { staticClass: "limiter-no-padding scrollbar" }, [
            _c(
              "div",
              { staticClass: "flex items-center w-100 justify-between f6" },
              [
                _c("TheTitle", {
                  attrs: {
                    backIcon: true,
                    icon: "psychology",
                    title: _vm.title,
                    large: true,
                    fontsize: "f5",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "pv2",
                    on: {
                      contextmenu: [
                        function ($event) {
                          return function () {
                            return (_vm.isModalDemoLoader = true)
                          }.apply(null, arguments)
                        },
                        function ($event) {
                          $event.preventDefault()
                          return function (e) {
                            return e.preventDefault()
                          }.apply(null, arguments)
                        },
                      ],
                    },
                  },
                  [
                    _c("BaseButtonRed", {
                      attrs: {
                        text: _vm.btnCreateText,
                        onClick: function () {
                          return (_vm.isModalLoader = true)
                        },
                        disabled:
                          _vm.finalDraft.length === 0 || _vm.isConverting,
                        loading: _vm.isConverting,
                      },
                      on: {
                        contextmenu: function ($event) {
                          return function () {
                            return (_vm.isModalLoader = true)
                          }.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.templateEnum == "GENERAL_CHATBOT"
              ? _c("div", [
                  _vm.isScriptLoading
                    ? _c("div", { staticClass: "w-100 flex" }, [
                        _c("div", {
                          staticClass:
                            "w-50 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mr3 mb3 br2 skeleton custom-size",
                        }),
                        _c("div", {
                          staticClass:
                            "w-50 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mr3 mb3 br2 skeleton custom-size",
                        }),
                      ])
                    : _c("div", { staticClass: "w-100 flex" }, [
                        _c("div", { staticClass: "w-50" }, [
                          _c(
                            "div",
                            { staticClass: "pb gray f5 flex items-center" },
                            [_vm._v("Enter your idea query here")]
                          ),
                          _c("div", { staticClass: "relative" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.topic,
                                  expression: "topic",
                                },
                              ],
                              staticClass:
                                "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-100 mt3",
                              attrs: {
                                type: "text",
                                placeholder: "Enter your query",
                              },
                              domProps: { value: _vm.topic },
                              on: {
                                keypress: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.sendQuery.apply(null, arguments)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.topic = $event.target.value
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "absolute top-0 right-1" },
                              [
                                _vm.isOpenAiFetching
                                  ? _c("div", {
                                      staticClass: "loader loader-inline mt3",
                                    })
                                  : _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons light-gray f4 v-mid mt3 pointer",
                                        on: { click: _vm.sendQuery },
                                      },
                                      [_vm._v("send")]
                                    ),
                              ]
                            ),
                          ]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.openAiRes,
                                expression: "openAiRes",
                              },
                            ],
                            staticClass:
                              "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-100 mt3 scrollbar mb2",
                            staticStyle: { height: "60vh" },
                            attrs: {
                              type: "text",
                              placeholder:
                                "Query response will be displayed here",
                            },
                            domProps: { value: _vm.openAiRes },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.openAiRes = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "flex justify-between" },
                            [
                              _c("BaseButtonBorder", {
                                attrs: {
                                  text: "Discard",
                                  onClick: function () {
                                    return (_vm.openAiRes = "")
                                  },
                                  disabled:
                                    _vm.openAiRes && _vm.openAiRes.length === 0,
                                },
                              }),
                              _c("BaseButtonBorder", {
                                attrs: {
                                  text: "Move >>",
                                  onClick: _vm.moveToFinal,
                                  disabled:
                                    _vm.openAiRes && _vm.openAiRes.length === 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "w-50 ml3" }, [
                          _c(
                            "div",
                            { staticClass: "pb gray f5 flex items-center" },
                            [_vm._v("Final Draft")]
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.finalDraft,
                                expression: "finalDraft",
                              },
                            ],
                            staticClass:
                              "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-100 mt3 scrollbar mb2",
                            staticStyle: { height: "67vh" },
                            attrs: {
                              type: "text",
                              placeholder: "Final draft, edit as required",
                            },
                            domProps: { value: _vm.finalDraft },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.finalDraft = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "flex justify-between" },
                            [
                              _c("BaseButtonBorder", {
                                attrs: {
                                  text: "Discard",
                                  onClick: _vm.discardFinalScript,
                                  disabled: _vm.finalDraft.length === 0,
                                },
                              }),
                              _c("div", { staticClass: "flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.isUnsavedChanges &&
                                              _vm.finalDraft.length,
                                            expression:
                                              "isUnsavedChanges && finalDraft.length",
                                          },
                                        ],
                                        staticClass: "silver mr2",
                                      },
                                      [
                                        _vm._v("unsaved "),
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons light-gray f4 v-mid",
                                          },
                                          [_vm._v("cached")]
                                        ),
                                      ]
                                    ),
                                    _c("BaseButtonBorder", {
                                      staticClass: "mr2",
                                      class: {
                                        "b-red":
                                          _vm.isUnsavedChanges &&
                                          _vm.finalDraft.length,
                                      },
                                      attrs: {
                                        text: "Save",
                                        onClick: _vm.saveDraft,
                                        disabled:
                                          _vm.finalDraft.length === 0 ||
                                          _vm.isSaveScriptLoading ||
                                          _vm.isUpdateScriptLoading,
                                        loading:
                                          _vm.isSaveScriptLoading ||
                                          _vm.isUpdateScriptLoading,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                ])
              : _c("div", { staticClass: "w-100 flex" }, [
                  _vm.isScriptLoading
                    ? _c("div", { staticClass: "w-100 flex" }, [
                        _c("div", {
                          staticClass:
                            "w-30 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mr3 mb3 br2 skeleton custom-size-category",
                        }),
                        _c("div", {
                          staticClass:
                            "w-70 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mr3 mb3 br2 skeleton custom-size",
                        }),
                      ])
                    : _c("div", { staticClass: "w-100 flex" }, [
                        _c(
                          "div",
                          { staticClass: "w-30" },
                          [
                            _c(
                              "div",
                              { staticClass: "pb gray f6 flex items-center" },
                              [_vm._v("Topic*")]
                            ),
                            _c("div", { staticClass: "relative flex" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.topic,
                                    expression: "topic",
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-gray b--gray ba br2 ph3 pv3 gray f6 white w-100 mt1",
                                attrs: { type: "text", placeholder: "" },
                                domProps: { value: _vm.topic },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.topic = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "drop-div dim",
                                  on: { click: _vm.toggleAIPanel },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons v-mid white text-shadow",
                                    },
                                    [_vm._v(" arrow_drop_down ")]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pb gray f6 flex items-center mt3",
                              },
                              [
                                _vm._v(
                                  "\n              Description\n              "
                                ),
                                _c("BaseTooltip", {
                                  attrs: {
                                    info: "The more information you give the better the response that is generated",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.description,
                                  expression: "description",
                                },
                              ],
                              staticClass:
                                "db bg-adori-gray b--gray br2 ph3 pv3 gray f6 white w-100 scrollbar mb2 mt1",
                              attrs: {
                                rows: "3",
                                type: "text",
                                placeholder: "",
                              },
                              domProps: { value: _vm.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.description = $event.target.value
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "pb gray f6 flex items-center" },
                              [
                                _vm._v("\n              Keywords "),
                                _c("BaseTooltip", {
                                  attrs: {
                                    info: "Keywords you want to be part of generated text",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "relative" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.keywords,
                                    expression: "keywords",
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-gray b--gray ba br2 ph3 pv3 gray f6 white w-100 mt1",
                                attrs: { type: "text", placeholder: "" },
                                domProps: { value: _vm.keywords },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.keywords = $event.target.value
                                  },
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pb gray f6 flex items-center mt3",
                              },
                              [_vm._v("Tone of voice")]
                            ),
                            _c("div", { staticClass: "relative flex" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.voiceTone,
                                    expression: "voiceTone",
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-gray b--gray ba br2 ph3 pv3 gray f6 white w-100 mt1",
                                attrs: { type: "text", placeholder: "" },
                                domProps: { value: _vm.voiceTone },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.voiceTone = $event.target.value
                                  },
                                },
                              }),
                              _c("span", { staticClass: "drop-div dim" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.voiceTone,
                                        expression: "voiceTone",
                                      },
                                    ],
                                    staticClass:
                                      "drop-selector bg-adori-gray white f6 br2 h2 ba b--gray scrollbar mw-75 pointer",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.voiceTone = $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function ($event) {},
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.voiceToneListData,
                                    function (item, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: item.title },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.title) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons v-mid white text-shadow",
                                  },
                                  [_vm._v(" arrow_drop_down ")]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pb gray f6 flex items-center mt3",
                              },
                              [_vm._v("Writing style")]
                            ),
                            _c("div", { staticClass: "relative flex" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.writingStyle,
                                    expression: "writingStyle",
                                  },
                                ],
                                staticClass:
                                  "db bg-adori-gray b--gray ba br2 ph3 pv3 gray f6 white w-100 mt1",
                                attrs: { type: "text", placeholder: "" },
                                domProps: { value: _vm.writingStyle },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.writingStyle = $event.target.value
                                  },
                                },
                              }),
                              _c("span", { staticClass: "drop-div dim" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.writingStyle,
                                        expression: "writingStyle",
                                      },
                                    ],
                                    staticClass:
                                      "drop-selector bg-adori-gray white f6 br2 h2 ba b--gray scrollbar mw-75 pointer",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.writingStyle = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function ($event) {},
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.writingStyleData,
                                    function (item, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: i,
                                          domProps: { value: item.title },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.title) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons v-mid white text-shadow",
                                  },
                                  [_vm._v(" arrow_drop_down ")]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pb gray f6 flex items-center mt3",
                              },
                              [_vm._v("Language")]
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.language,
                                    expression: "language",
                                  },
                                ],
                                staticClass:
                                  "ph2 bg-adori-gray white f6 w-100 br2 h2 ba b--gray mt1",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.language = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {},
                                  ],
                                },
                              },
                              _vm._l(_vm.languageData, function (item, i) {
                                return _c(
                                  "option",
                                  { key: i, domProps: { value: item } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c("BaseButtonRed", {
                              staticClass: "gen-btn mt3",
                              attrs: {
                                text: _vm.btnGenerateText,
                                icon: "auto_fix_high",
                                onClick: _vm.sendQuery,
                                loading: _vm.isOpenAiFetching,
                                disabled: _vm.isOpenAiFetching,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "w-70 ml3" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.finalDraft,
                                expression: "finalDraft",
                              },
                            ],
                            staticClass:
                              "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-100 mt3 scrollbar mb2",
                            staticStyle: { height: "67vh" },
                            attrs: {
                              type: "text",
                              placeholder:
                                "Generated text will show here, edit as required",
                            },
                            domProps: { value: _vm.finalDraft },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.finalDraft = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "flex justify-between" },
                            [
                              _c("BaseButtonBorder", {
                                attrs: {
                                  text: "Discard",
                                  onClick: _vm.discardFinalScript,
                                  disabled: _vm.finalDraft.length === 0,
                                },
                              }),
                              _c("div", { staticClass: "flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.isUnsavedChanges &&
                                              _vm.finalDraft.length,
                                            expression:
                                              "isUnsavedChanges && finalDraft.length",
                                          },
                                        ],
                                        staticClass: "silver mr2",
                                      },
                                      [_vm._v("unsaved ")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.scriptName,
                                          expression: "scriptName",
                                        },
                                      ],
                                      staticClass:
                                        "f5 v-mid db bg-adori-gray b--gray ba br2 ph1 mr3 pv1 gray white w-100",
                                      attrs: {
                                        type: "text",
                                        placeholder: "Script name",
                                      },
                                      domProps: { value: _vm.scriptName },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.scriptName = $event.target.value
                                        },
                                      },
                                    }),
                                    _c("BaseButtonBorder", {
                                      staticClass: "mr2",
                                      class: {
                                        "b-red":
                                          _vm.isUnsavedChanges &&
                                          _vm.finalDraft.length,
                                      },
                                      attrs: {
                                        text: "Save",
                                        onClick: _vm.saveDraft,
                                        disabled:
                                          _vm.finalDraft.length === 0 ||
                                          _vm.isSaveScriptLoading ||
                                          _vm.isUpdateScriptLoading,
                                        loading:
                                          _vm.isSaveScriptLoading ||
                                          _vm.isUpdateScriptLoading,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                ]),
          ]),
          _vm.isModalLoader
            ? _c("ModalLoader", {
                attrs: {
                  isLoaderActive: _vm.isConverting,
                  loaderText: _vm.btnCreateText,
                },
                on: {
                  closeModal: function ($event) {
                    _vm.isModalLoader = !_vm.isModalLoader
                  },
                  "voice-details": _vm.handleVoiceDetails,
                },
              })
            : _vm._e(),
          _vm.isModalDemoLoader
            ? _c("ModalDemoLoader", {
                on: {
                  closeModal: function ($event) {
                    _vm.isModalDemoLoader = !_vm.isModalDemoLoader
                  },
                },
              })
            : _vm._e(),
          _c("AIPanel", {
            ref: "_searchAndSelectTray",
            attrs: {
              data: _vm.topicListData,
              onSelectItem: _vm.selectItem,
              context: "TOPIC_RECOMMENDATION",
              onClose: function () {
                _vm.showAIPanel = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }